<template>
  <div>
    <section
      v-if="property"
      class="n-section-primary !tw-pb-0 !tw-pt-36"
      :style="{
        backgroundColor: vars.backgroundColor ? `var(--c-${vars.backgroundColor})` : 'var(--c-section_secondary)',
      }"
    >
      <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-justify-between tw-pb-6">
          <library-properties-back-to-all-properties-navbar color="var(--c-secondary)" />
        </div>
        <div class="mt-5 tw-grid tw-grid-cols-12 tw-items-center tw-justify-between">
          <div class="tw-col-span-12 tw-flex tw-flex-col lg:tw-col-span-7">
            <h2>{{ property.advert_heading }}</h2>
            <div class="tw-mt-2 tw-flex tw-items-center tw-gap-1">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 11.25C9.38193 11.25 8.77775 11.0667 8.26384 10.7233C7.74994 10.38 7.3494 9.89191 7.11288 9.32089C6.87635 8.74987 6.81447 8.12154 6.93505 7.51534C7.05563 6.90915 7.35325 6.35233 7.79029 5.91529C8.22733 5.47825 8.78415 5.18063 9.39034 5.06005C9.99654 4.93947 10.6249 5.00135 11.1959 5.23788C11.7669 5.4744 12.255 5.87494 12.5983 6.38884C12.9417 6.90275 13.125 7.50693 13.125 8.125C13.124 8.9535 12.7945 9.74778 12.2086 10.3336C11.6228 10.9195 10.8285 11.249 10 11.25ZM10 6.25C9.62916 6.25 9.26665 6.35997 8.95831 6.566C8.64997 6.77202 8.40964 7.06486 8.26773 7.40747C8.12581 7.75008 8.08868 8.12708 8.16103 8.4908C8.23338 8.85451 8.41195 9.1886 8.67418 9.45083C8.9364 9.71305 9.27049 9.89163 9.63421 9.96397C9.99792 10.0363 10.3749 9.99919 10.7175 9.85728C11.0601 9.71536 11.353 9.47504 11.559 9.1667C11.765 8.85835 11.875 8.49584 11.875 8.125C11.8745 7.62787 11.6768 7.15125 11.3253 6.79972C10.9738 6.4482 10.4971 6.2505 10 6.25Z"
                  fill="black"
                />
                <path
                  d="M10 18.75L4.72751 12.5319C4.65425 12.4385 4.58174 12.3445 4.51001 12.25C3.60937 11.0636 3.12282 9.61452 3.12501 8.125C3.12501 6.30164 3.84934 4.55295 5.13865 3.26364C6.42796 1.97433 8.17664 1.25 10 1.25C11.8234 1.25 13.5721 1.97433 14.8614 3.26364C16.1507 4.55295 16.875 6.30164 16.875 8.125C16.8772 9.61384 16.3909 11.0623 15.4906 12.2481L15.49 12.25C15.49 12.25 15.3025 12.4963 15.2744 12.5294L10 18.75ZM5.50813 11.4969C5.50813 11.4969 5.65376 11.6894 5.68688 11.7306L10 16.8175L14.3188 11.7237C14.3463 11.6894 14.4925 11.4956 14.4931 11.495C15.2289 10.5257 15.6265 9.34189 15.625 8.125C15.625 6.63316 15.0324 5.20242 13.9775 4.14752C12.9226 3.09263 11.4918 2.5 10 2.5C8.50816 2.5 7.07742 3.09263 6.02253 4.14752C4.96764 5.20242 4.37501 6.63316 4.37501 8.125C4.3737 9.34265 4.77174 10.5271 5.50813 11.4969Z"
                  fill="black"
                />
              </svg>
              <p class="tw-capitalize">{{ property.display_address }}</p>
            </div>
            <div class="tw-mt-3 tw-flex tw-items-center tw-gap-1">
              <div class="tw-flex tw-flex-row tw-items-center tw-gap-4">
                <p>Beds: {{ property.bedroom }}</p>
                <p>Baths: {{ property.bathroom }}</p>
                <p>Receptions: {{ property.reception }}</p>
              </div>
            </div>
            <div class="tw-flex tw-items-center tw-gap-5 tw-py-5" v-if="variant === variants.v2">
              <button @click="onSendLead()" class="n-primary tw-flex tw-max-w-72 tw-items-center tw-justify-center tw-gap-4">Book a viewing</button>
            </div>
          </div>
          <div class="tw-col-span-12 tw-flex tw-flex-col tw-justify-between lg:tw-col-span-5 lg:tw-items-end lg:tw-justify-end lg:tw-gap-8">
            <div class="tw-flex tw-w-full tw-items-center tw-justify-between max-lg:tw-pt-4 lg:tw-justify-end lg:tw-gap-8">
              <div v-if="property.price">
                <p v-if="property.price.type.name">
                  {{ property.price.type.name }}
                </p>
                <h5 class="!tw-text-3xl">{{ property.price.price }}</h5>
              </div>

              <div
                class="tw-flex tw-h-12 tw-w-12 tw-cursor-pointer tw-items-center tw-justify-center"
                style="background-color: var(--c-secondary); border-radius: var(--rounded)"
                @click="onShared()"
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15 18.3333C14.3056 18.3333 13.7153 18.0903 13.2292 17.6042C12.7431 17.118 12.5 16.5278 12.5 15.8333C12.5 15.7361 12.5069 15.6353 12.5208 15.5308C12.5347 15.4264 12.5556 15.3328 12.5833 15.25L6.70833 11.8333C6.47222 12.0417 6.20833 12.205 5.91667 12.3233C5.625 12.4417 5.31944 12.5005 5 12.5C4.30556 12.5 3.71528 12.2569 3.22917 11.7708C2.74306 11.2847 2.5 10.6944 2.5 9.99999C2.5 9.30555 2.74306 8.71527 3.22917 8.22916C3.71528 7.74305 4.30556 7.49999 5 7.49999C5.31944 7.49999 5.625 7.55916 5.91667 7.67749C6.20833 7.79582 6.47222 7.95888 6.70833 8.16666L12.5833 4.74999C12.5556 4.66666 12.5347 4.57305 12.5208 4.46916C12.5069 4.36527 12.5 4.26443 12.5 4.16666C12.5 3.47221 12.7431 2.88193 13.2292 2.39582C13.7153 1.90971 14.3056 1.66666 15 1.66666C15.6944 1.66666 16.2847 1.90971 16.7708 2.39582C17.2569 2.88193 17.5 3.47221 17.5 4.16666C17.5 4.8611 17.2569 5.45138 16.7708 5.93749C16.2847 6.4236 15.6944 6.66666 15 6.66666C14.6806 6.66666 14.375 6.60777 14.0833 6.48999C13.7917 6.37221 13.5278 6.20888 13.2917 5.99999L7.41667 9.41666C7.44444 9.49999 7.46528 9.59388 7.47917 9.69832C7.49306 9.80277 7.5 9.90332 7.5 9.99999C7.5 10.0972 7.49306 10.198 7.47917 10.3025C7.46528 10.4069 7.44444 10.5005 7.41667 10.5833L13.2917 14C13.5278 13.7917 13.7917 13.6286 14.0833 13.5108C14.375 13.393 14.6806 13.3339 15 13.3333C15.6944 13.3333 16.2847 13.5764 16.7708 14.0625C17.2569 14.5486 17.5 15.1389 17.5 15.8333C17.5 16.5278 17.2569 17.118 16.7708 17.6042C16.2847 18.0903 15.6944 18.3333 15 18.3333Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div class="tw-pt-6">
              <button class="n-primary" @click="onSendLead()" v-if="variant !== variants.v2">ARRANGE A VIEWING</button>
            </div>
          </div>
        </div>
      </div>
      <!-- GALLERY: START -->
      <template v-if="variant === variants.v1">
        <div v-if="property.is_rent" class="tw-mt-16 tw-w-full" :class="!isMobileDevice ? 'tw-grid tw-grid-cols-12 tw-gap-x-3' : ''">
          <div v-if="!isMobileDevice" class="tw-col-span-12 tw-h-full tw-w-full lg:tw-col-span-6" @click="onClickedImage(null)">
            <library-image
              :src="primaryFivePhotos[0].background"
              class="tw-h-full tw-cursor-pointer"
              alt="property first image"
              :title="property.advert_heading"
              @click="onClickedImage(primaryFivePhotos[0])"
              :design-width="771"
              :design-height="512"
              :style="`border-radius: var(--rounded)`"
            >
              <template #overlay-item>
                <div class="nc-first-image-overlay-item">
                  <div v-if="property && property.medias" style="" class="tw-text-md tw-px-2 tw-py-1">1/{{ property.medias.length }}</div>
                </div>
              </template>
            </library-image>
          </div>
          <div v-if="!isMobileDevice" class="tw-col-span-12 tw-grid tw-h-full tw-grid-cols-12 tw-gap-x-3 md:tw-col-span-6">
            <div class="tw-col-span-6 tw-flex tw-flex-row tw-gap-y-3 md:tw-flex-col">
              <library-image
                :src="primaryFivePhotos[1].background"
                alt="property first image"
                class="tw-cursor-pointer"
                @click="onClickedImage(primaryFivePhotos[1])"
                :title="property.advert_heading"
                :design-width="364"
                :design-height="246"
                :style="`border-radius: var(--rounded)`"
              />

              <library-image
                :src="primaryFivePhotos[2].background"
                alt="property second image"
                class="tw-cursor-pointer"
                @click="onClickedImage(primaryFivePhotos[2])"
                :title="property.advert_heading"
                :design-width="364"
                :design-height="246"
                :style="`border-radius: var(--rounded)`"
              />
            </div>
            <div class="tw-col-span-6 tw-flex tw-flex-row tw-gap-y-3 md:tw-flex-col">
              <library-image
                :src="primaryFivePhotos[3].background"
                class="tw-cursor-pointer"
                @click="onClickedImage(primaryFivePhotos[3])"
                :design-width="364"
                :design-height="246"
                :style="`border-radius: var(--rounded)`"
              />
              <library-image
                :src="primaryFivePhotos[4].background"
                class="tw-cursor-pointer"
                @click="onClickedImage(primaryFivePhotos[4])"
                :design-width="364"
                :design-height="246"
                :style="`border-radius: var(--rounded)`"
              />
            </div>
          </div>
          <div v-else-if="isMobileDevice" class="n-main-container tw-w-screen">
            <Swiper
              :modules="[SwiperNavigation]"
              :pagination="true"
              :slides-per-view="1"
              :space-between="1"
              effect="fade"
              :clickable="true"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              class="tw-w-full"
              @swiper="onSwiper"
            >
              <SwiperSlide v-for="(item, index) in primaryFivePhotos" :key="index" class="tw-w-full">
                <library-image :src="item.background" @click="onClickedImage(item)" />
              </SwiperSlide>
              <div class="tw-flex tw-items-center tw-justify-between">
                <div class="swiper-button-prev tw-cursor-pointer tw-rounded-md tw-bg-white tw-px-2 tw-py-5 lg:tw-px-3">
                  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.75 7.5H5.25C4.83 7.5 4.5 7.17 4.5 6.75C4.5 6.33 4.83 6 5.25 6H18.75C19.17 6 19.5 6.33 19.5 6.75C19.5 7.17 19.17 7.5 18.75 7.5Z"
                      fill="black"
                    />
                    <path
                      d="M9 12.75C8.90164 12.7512 8.80411 12.7318 8.71369 12.6931C8.62328 12.6543 8.54197 12.5971 8.475 12.525L3.225 7.275C2.925 6.975 2.925 6.51 3.225 6.21L8.475 0.975C8.775 0.675 9.24 0.675 9.54 0.975C9.84 1.275 9.84 1.74 9.54 2.04L4.815 6.765L9.54 11.49C9.84 11.79 9.84 12.255 9.54 12.555C9.39 12.705 9.195 12.78 9.015 12.78L9 12.75Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div class="swiper-button-next tw-cursor-pointer tw-rounded-md tw-bg-white tw-px-2 tw-py-5 lg:tw-px-3">
                  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.25 7.5H18.75C19.17 7.5 19.5 7.17 19.5 6.75C19.5 6.33 19.17 6 18.75 6H5.25C4.83 6 4.5 6.33 4.5 6.75C4.5 7.17 4.83 7.5 5.25 7.5Z"
                      fill="black"
                    />
                    <path
                      d="M15 12.75C15.0984 12.7512 15.1959 12.7318 15.2863 12.6931C15.3767 12.6543 15.458 12.5971 15.525 12.525L20.775 7.275C21.075 6.975 21.075 6.51 20.775 6.21L15.525 0.975C15.225 0.675 14.76 0.675 14.46 0.975C14.16 1.275 14.16 1.74 14.46 2.04L19.185 6.765L14.46 11.49C14.16 11.79 14.16 12.255 14.46 12.555C14.61 12.705 14.805 12.78 14.985 12.78L15 12.75Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
        <!-- Sale -->
        <div v-else class="tw-mt-16 tw-w-screen tw-gap-x-1">
          <Swiper
            :modules="[SwiperNavigation]"
            :pagination="true"
            :slides-per-view="3"
            :space-between="3"
            effect="fade"
            :clickable="true"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            :breakpoints="{
              320: {
                slidesPerView: 1,
                spaceBetween: 3,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 3,
              },
            }"
            class="tw-w-full"
            @swiper="onSwiper"
          >
            <SwiperSlide v-for="(item, index) in property.medias" :key="index">
              <div>
                <library-image :src="item.background" @click="onClickedImage(item)" :design-width="638" :design-height="430">
                  <template #overlay-item>
                    <div v-if="index === 1">
                      <div class="nc-first-image-overlay-item">
                        <div v-if="property && property.medias" style="" class="tw-text-md tw-px-2 tw-py-1">1/{{ property.medias.length }}</div>
                      </div>
                    </div>
                  </template>
                </library-image>
              </div>
            </SwiperSlide>
            <div class="tw-flex tw-items-center tw-justify-between">
              <div class="swiper-button-prev tw-cursor-pointer tw-rounded-md tw-bg-white tw-px-2 tw-py-5 lg:tw-px-3">
                <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.75 7.5H5.25C4.83 7.5 4.5 7.17 4.5 6.75C4.5 6.33 4.83 6 5.25 6H18.75C19.17 6 19.5 6.33 19.5 6.75C19.5 7.17 19.17 7.5 18.75 7.5Z"
                    fill="black"
                  />
                  <path
                    d="M9 12.75C8.90164 12.7512 8.80411 12.7318 8.71369 12.6931C8.62328 12.6543 8.54197 12.5971 8.475 12.525L3.225 7.275C2.925 6.975 2.925 6.51 3.225 6.21L8.475 0.975C8.775 0.675 9.24 0.675 9.54 0.975C9.84 1.275 9.84 1.74 9.54 2.04L4.815 6.765L9.54 11.49C9.84 11.79 9.84 12.255 9.54 12.555C9.39 12.705 9.195 12.78 9.015 12.78L9 12.75Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div class="swiper-button-next tw-cursor-pointer tw-rounded-md tw-bg-white tw-px-2 tw-py-5 lg:tw-px-3">
                <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.25 7.5H18.75C19.17 7.5 19.5 7.17 19.5 6.75C19.5 6.33 19.17 6 18.75 6H5.25C4.83 6 4.5 6.33 4.5 6.75C4.5 7.17 4.83 7.5 5.25 7.5Z"
                    fill="black"
                  />
                  <path
                    d="M15 12.75C15.0984 12.7512 15.1959 12.7318 15.2863 12.6931C15.3767 12.6543 15.458 12.5971 15.525 12.525L20.775 7.275C21.075 6.975 21.075 6.51 20.775 6.21L15.525 0.975C15.225 0.675 14.76 0.675 14.46 0.975C14.16 1.275 14.16 1.74 14.46 2.04L19.185 6.765L14.46 11.49C14.16 11.79 14.16 12.255 14.46 12.555C14.61 12.705 14.805 12.78 14.985 12.78L15 12.75Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </Swiper>
        </div>
      </template>
      <!-- GALLERY: END -->

      <!-- variant v2 for gallery -->
      <template v-else-if="variant === variants.v2 && vars.galleryVersionText === 'v2'">
        <div v-if="property.featured_video" class="tw-w-full tw-py-5">
          <div v-if="!isMobileDevice" class="tw-grid tw-grid-cols-[2.5fr_7.25fr_2.25fr] tw-gap-5">
            <div class="tw-relative">
              <div class="tw-aspect-[9/16] tw-h-full tw-w-full">
                <iframe
                  class="tw-absolute tw-inset-0 tw-h-full tw-w-full"
                  :src="property.featured_video?.mediaLink"
                  style="border-radius: var(--rounded)"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div>
              <library-image
                :src="primaryFivePhotos[0].background"
                class="tw-h-full tw-cursor-pointer"
                alt="property first image"
                :title="property.advert_heading"
                @click="onClickedImage(primaryFivePhotos[0])"
                :design-width="878"
                :design-height="585"
                :style="`border-radius: var(--rounded)`"
              >
                <template #overlay-item>
                  <div class="nc-first-image-overlay-item">
                    <div v-if="property && property.medias" style="border-radius: var(--rounded-sm)" class="tw-text-md tw-bg-white tw-px-2 tw-py-1">
                      1/{{ property.medias.length }}
                    </div>
                  </div>
                </template>
              </library-image>
            </div>

            <div class="tw-flex tw-flex-col tw-justify-between">
              <library-image
                :src="primaryFivePhotos[1].background"
                alt="property first image"
                class="tw-cursor-pointer"
                @click="onClickedImage(primaryFivePhotos[1])"
                :title="property.advert_heading"
                :design-width="270"
                :design-height="180"
                :style="`border-radius: var(--rounded)`"
              />
              <library-image
                :src="primaryFivePhotos[2].background"
                alt="property first image"
                class="tw-cursor-pointer"
                @click="onClickedImage(primaryFivePhotos[2])"
                :title="property.advert_heading"
                :design-width="270"
                :design-height="180"
                :style="`border-radius: var(--rounded)`"
              />
              <library-image
                :src="primaryFivePhotos[3].background"
                alt="property first image"
                class="tw-cursor-pointer"
                @click="onClickedImage(primaryFivePhotos[3])"
                :title="property.advert_heading"
                :design-width="270"
                :design-height="180"
                :style="`border-radius: var(--rounded)`"
              >
                <template #overlay-item>
                  <div
                    v-if="property && property.medias"
                    class="tw-absolute tw-inset-0 tw-z-10 tw-h-full tw-w-full tw-bg-black tw-opacity-30"
                    style="border-radius: var(--rounded-sm)"
                  ></div>
                  <h3 class="tw-z-20 tw-text-white">+{{ property.medias.length - 4 }}</h3>
                </template>
              </library-image>
            </div>
          </div>
          <div v-else-if="isMobileDevice">
            <Swiper
              :modules="[SwiperNavigation]"
              :pagination="true"
              :slides-per-view="1"
              :space-between="1"
              effect="fade"
              :clickable="true"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              class="tw-w-full"
              @swiper="onSwiper"
            >
              <SwiperSlide v-for="(item, index) in primaryFivePhotos" :key="index" class="tw-w-full">
                <library-image :src="item.background" @click="onClickedImage(item)" />
              </SwiperSlide>
              <div class="tw-flex tw-items-center tw-justify-between">
                <div class="swiper-button-prev tw-cursor-pointer tw-rounded-md tw-bg-white tw-px-2 tw-py-5 lg:tw-px-3">
                  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.75 7.5H5.25C4.83 7.5 4.5 7.17 4.5 6.75C4.5 6.33 4.83 6 5.25 6H18.75C19.17 6 19.5 6.33 19.5 6.75C19.5 7.17 19.17 7.5 18.75 7.5Z"
                      fill="black"
                    />
                    <path
                      d="M9 12.75C8.90164 12.7512 8.80411 12.7318 8.71369 12.6931C8.62328 12.6543 8.54197 12.5971 8.475 12.525L3.225 7.275C2.925 6.975 2.925 6.51 3.225 6.21L8.475 0.975C8.775 0.675 9.24 0.675 9.54 0.975C9.84 1.275 9.84 1.74 9.54 2.04L4.815 6.765L9.54 11.49C9.84 11.79 9.84 12.255 9.54 12.555C9.39 12.705 9.195 12.78 9.015 12.78L9 12.75Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div class="swiper-button-next tw-cursor-pointer tw-rounded-md tw-bg-white tw-px-2 tw-py-5 lg:tw-px-3">
                  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.25 7.5H18.75C19.17 7.5 19.5 7.17 19.5 6.75C19.5 6.33 19.17 6 18.75 6H5.25C4.83 6 4.5 6.33 4.5 6.75C4.5 7.17 4.83 7.5 5.25 7.5Z"
                      fill="black"
                    />
                    <path
                      d="M15 12.75C15.0984 12.7512 15.1959 12.7318 15.2863 12.6931C15.3767 12.6543 15.458 12.5971 15.525 12.525L20.775 7.275C21.075 6.975 21.075 6.51 20.775 6.21L15.525 0.975C15.225 0.675 14.76 0.675 14.46 0.975C14.16 1.275 14.16 1.74 14.46 2.04L19.185 6.765L14.46 11.49C14.16 11.79 14.16 12.255 14.46 12.555C14.61 12.705 14.805 12.78 14.985 12.78L15 12.75Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
        <div v-else class="tw-mt-16 tw-w-full" :class="!isMobileDevice ? 'tw-grid tw-grid-cols-12 tw-gap-x-3' : ''">
          <div v-if="!isMobileDevice" class="tw-col-span-12 tw-h-full tw-w-full lg:tw-col-span-7" @click="onClickedImage(null)">
            <library-image
              :src="primarySevenPhotos[0].background"
              class="tw-h-full tw-cursor-pointer"
              alt="property first image"
              :title="property.advert_heading"
              @click="onClickedImage(primarySevenPhotos[0])"
              :design-width="900"
              :design-height="611"
              :style="`border-radius: var(--rounded)`"
            />
          </div>

          <div v-if="!isMobileDevice" class="tw-col-span-12 tw-grid tw-h-full tw-grid-cols-12 tw-gap-x-3 md:tw-col-span-5">
            <div class="tw-col-span-6 tw-flex tw-flex-row tw-gap-y-3 md:tw-flex-col">
              <library-image
                :src="primarySevenPhotos[1].background"
                alt="property first image"
                class="tw-cursor-pointer"
                @click="onClickedImage(primarySevenPhotos[1])"
                :title="property.advert_heading"
                :design-width="276"
                :design-height="184"
                :style="`border-radius: var(--rounded)`"
              />

              <library-image
                :src="primarySevenPhotos[2].background"
                alt="property second image"
                class="tw-cursor-pointer"
                @click="onClickedImage(primarySevenPhotos[2])"
                :title="property.advert_heading"
                :design-width="276"
                :design-height="184"
                :style="`border-radius: var(--rounded)`"
              />

              <library-image
                :src="primarySevenPhotos[3].background"
                alt="property second image"
                class="tw-cursor-pointer"
                @click="onClickedImage(primarySevenPhotos[3])"
                :title="property.advert_heading"
                :design-width="276"
                :design-height="184"
                :style="`border-radius: var(--rounded)`"
              />
            </div>
            <div class="tw-col-span-6 tw-flex tw-flex-row tw-gap-y-3 md:tw-flex-col">
              <library-image
                :src="primarySevenPhotos[4].background"
                class="tw-cursor-pointer"
                @click="onClickedImage(primarySevenPhotos[4])"
                :design-width="276"
                :design-height="184"
                :style="`border-radius: var(--rounded)`"
              />
              <library-image
                :src="primarySevenPhotos[5].background"
                class="tw-cursor-pointer"
                @click="onClickedImage(primarySevenPhotos[5])"
                :design-width="276"
                :design-height="184"
                :style="`border-radius: var(--rounded)`"
              />

              <library-image
                :src="primarySevenPhotos[6].background"
                alt="property second image"
                class="tw-cursor-pointer"
                @click="onClickedImage(primarySevenPhotos[6])"
                :title="property.advert_heading"
                :design-width="276"
                :design-height="184"
                :style="`border-radius: var(--rounded)`"
              >
                <template #overlay-item>
                  <div
                    v-if="property && property.medias"
                    class="tw-absolute tw-inset-0 tw-z-10 tw-h-full tw-w-full tw-bg-black tw-opacity-30"
                    style="border-radius: var(--rounded-sm)"
                  ></div>
                  <h3 class="tw-z-20 tw-text-white">+{{ property.medias.length - 4 }}</h3>
                </template>
              </library-image>
            </div>
          </div>
          <div v-else-if="isMobileDevice" class="n-main-container tw-w-screen">
            <Swiper
              :modules="[SwiperNavigation]"
              :pagination="true"
              :slides-per-view="1"
              :space-between="1"
              effect="fade"
              :clickable="true"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              class="tw-w-full"
              @swiper="onSwiper"
            >
              <SwiperSlide v-for="(item, index) in primaryFivePhotos" :key="index" class="tw-w-full">
                <library-image :src="item.background" @click="onClickedImage(item)" />
              </SwiperSlide>
              <div class="tw-flex tw-items-center tw-justify-between">
                <div class="swiper-button-prev tw-cursor-pointer tw-rounded-md tw-bg-white tw-px-2 tw-py-5 lg:tw-px-3">
                  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.75 7.5H5.25C4.83 7.5 4.5 7.17 4.5 6.75C4.5 6.33 4.83 6 5.25 6H18.75C19.17 6 19.5 6.33 19.5 6.75C19.5 7.17 19.17 7.5 18.75 7.5Z"
                      fill="black"
                    />
                    <path
                      d="M9 12.75C8.90164 12.7512 8.80411 12.7318 8.71369 12.6931C8.62328 12.6543 8.54197 12.5971 8.475 12.525L3.225 7.275C2.925 6.975 2.925 6.51 3.225 6.21L8.475 0.975C8.775 0.675 9.24 0.675 9.54 0.975C9.84 1.275 9.84 1.74 9.54 2.04L4.815 6.765L9.54 11.49C9.84 11.79 9.84 12.255 9.54 12.555C9.39 12.705 9.195 12.78 9.015 12.78L9 12.75Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div class="swiper-button-next tw-cursor-pointer tw-rounded-md tw-bg-white tw-px-2 tw-py-5 lg:tw-px-3">
                  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.25 7.5H18.75C19.17 7.5 19.5 7.17 19.5 6.75C19.5 6.33 19.17 6 18.75 6H5.25C4.83 6 4.5 6.33 4.5 6.75C4.5 7.17 4.83 7.5 5.25 7.5Z"
                      fill="black"
                    />
                    <path
                      d="M15 12.75C15.0984 12.7512 15.1959 12.7318 15.2863 12.6931C15.3767 12.6543 15.458 12.5971 15.525 12.525L20.775 7.275C21.075 6.975 21.075 6.51 20.775 6.21L15.525 0.975C15.225 0.675 14.76 0.675 14.46 0.975C14.16 1.275 14.16 1.74 14.46 2.04L19.185 6.765L14.46 11.49C14.16 11.79 14.16 12.255 14.46 12.555C14.61 12.705 14.805 12.78 14.985 12.78L15 12.75Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </template>
    </section>
    <!-- PROPERTY DETAIL -->
    <section v-if="variant === variants.v1" class="n-section-primary !tw-pt-0" style="background-color: var(--c-section_primary)">
      <div>
        <div class="tw-grid tw-grid-cols-12 md:tw-gap-x-10">
          <div class="tw-col-span-12 tw-flex tw-flex-col tw-gap-y-12 lg:tw-col-span-7 xl:tw-col-span-8">
            <!-- Property Video, Send Lead: START -->
            <!-- <property-detail-actions
            v-if="isMobileDevice"
            :property="property"
            :component="component"
            class="tw-col-span-12"
            @on-send-lead="onSendLead()"
          /> -->
            <!-- Property Video, Send Lead: END -->

            <div>
              <!-- <library-title text="Property Information" tag="h3"></library-title> -->

              <div class="tab-content tw-mt-4">
                <div v-show="activeTab === 'features'" id="features" role="tabpanel">
                  <div
                    class="tw-flex tw-flex-col tw-rounded tw-border tw-p-6 lg:tw-p-10"
                    style="background: var(--c-section_secondary); border-radius: var(--rounded); border-color: var(--c-border)"
                  >
                    <h3>Features</h3>
                    <div class="tw-mt-4 tw-grid tw-grid-cols-12 tw-gap-4">
                      <div
                        v-for="(feature, index) in property.features"
                        :key="`property-feature-${index}`"
                        class="tw-col-span-12 tw-flex tw-h-[40px] tw-items-center tw-gap-x-3 md:tw-col-span-6"
                      >
                        <span>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z" fill="#BFA86C" />
                          </svg>
                        </span>
                        <library-text class="tw-text-sm tw-font-semibold tw-uppercase" :text="feature"> </library-text>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="activeTab === 'information'" id="information" role="tabpanel">
                  <div
                    class="tw-rounded tw-border tw-p-6 lg:tw-p-10"
                    style="background: var(--c-section_secondary); border-radius: var(--rounded); border-color: var(--c-border)"
                  >
                    <div class="tw-flex tw-items-center tw-gap-4 tw-pb-8">
                      <div
                        v-if="property.bedroom"
                        class="tw-px-4 tw-py-3"
                        style="background-color: var(--c-section_primary); border-radius: var(--rounded)"
                      >
                        <h6>Beds: {{ property.bedroom }}</h6>
                      </div>
                      <div
                        v-if="property.bathroom"
                        class="tw-px-4 tw-py-3"
                        style="background-color: var(--c-section_primary); border-radius: var(--rounded)"
                      >
                        <h6>Baths: {{ property.bathroom }}</h6>
                      </div>
                      <div
                        v-if="property.reception"
                        class="tw-px-4 tw-py-3"
                        style="background-color: var(--c-section_primary); border-radius: var(--rounded)"
                      >
                        <h6>Reception: {{ property.reception }}</h6>
                      </div>
                    </div>

                    <div v-if="property.description">
                      <h3 class="tw-pb-4">Property Info</h3>
                      <div v-html="property.description"></div>
                    </div>

                    <div class="tw-pt-8">
                      <h3>Amenties</h3>
                      <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-pt-4">
                        <div
                          v-for="(criteria, index) in property?.criteria_list"
                          :key="`criteria-${index}`"
                          class="tw-col-span-12 md:tw-col-span-6 2xl:tw-col-span-4"
                        >
                          <div style="">
                            <div
                              class="tw-flex tw-items-center tw-gap-4 tw-px-4 tw-py-3"
                              style="background-color: var(--c-section_primary); border-radius: var(--rounded)"
                            >
                              <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_726_237)">
                                  <path
                                    d="M50.5655 25.9352L28.0655 3.43521C27.7845 3.15584 27.4043 2.99902 27.008 2.99902C26.6117 2.99902 26.2316 3.15584 25.9505 3.43521L3.45053 25.9352C3.20479 26.2222 3.07638 26.5913 3.09096 26.9688C3.10554 27.3463 3.26204 27.7044 3.52918 27.9716C3.79633 28.2387 4.15444 28.3952 4.53195 28.4098C4.90946 28.4244 5.27858 28.296 5.56553 28.0502L27.0005 6.61521L48.4355 28.0652C48.7225 28.311 49.0916 28.4394 49.4691 28.4248C49.8466 28.4102 50.2047 28.2537 50.4719 27.9866C50.739 27.7194 50.8955 27.3613 50.9101 26.9838C50.9247 26.6063 50.7963 26.2372 50.5505 25.9502L50.5655 25.9352Z"
                                    fill="var(--c-secondary)"
                                  />
                                  <path
                                    d="M42 48.0001H34.5V33.0001H19.5V48.0001H12V27.0001L9 30.0001V48.0001C9 48.7958 9.31607 49.5588 9.87868 50.1215C10.4413 50.6841 11.2044 51.0001 12 51.0001H22.5V36.0001H31.5V51.0001H42C42.7957 51.0001 43.5587 50.6841 44.1213 50.1215C44.6839 49.5588 45 48.7958 45 48.0001V29.6401L42 26.6401V48.0001Z"
                                    fill="var(--c-secondary)"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_726_237">
                                    <rect width="54" height="54" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>

                              <div class="tw-flex tw-flex-col">
                                <p>{{ criteria.title }}</p>
                                <h6>{{ criteria.value }}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="activeTab === 'video'" id="video" role="tabpanel">
                  <div
                    class="tw-rounded tw-border tw-p-6 lg:tw-p-10"
                    style="background: var(--c-section_secondary); border-radius: var(--rounded); border-color: var(--c-border)"
                  >
                    <h3 class="tw-pb-8">Property Video</h3>
                    <div v-if="property?.featured_video">
                      <theme2-components-video-popup :component="component" :video-source="property.featured_video?.mediaLink" />
                    </div>
                  </div>
                </div>

                <!-- FLOOR PLANS -->

                <div v-show="activeTab === 'virtual-tour'" id="virtual-tour" role="tabpanel">
                  <div v-if="property.virtual_tour">
                    <nuxt-link
                      v-if="property && property.virtual_tour"
                      :to="property.virtual_tour?.mediaLink"
                      class="n-primary tw-flex tw-max-w-72 tw-items-center tw-justify-center tw-gap-4"
                    >
                      <div>
                        <svg width="36" height="36" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.83398 17.5L8.33398 14.1667M13.334 17.5L11.6673 14.1667L9.16732 11.6667L10.0007 6.66667M10.0007 3.33333C10.0007 3.55435 10.0884 3.76631 10.2447 3.92259C10.401 4.07887 10.613 4.16667 10.834 4.16667C11.055 4.16667 11.267 4.07887 11.4232 3.92259C11.5795 3.76631 11.6673 3.55435 11.6673 3.33333C11.6673 3.11232 11.5795 2.90036 11.4232 2.74408C11.267 2.5878 11.055 2.5 10.834 2.5C10.613 2.5 10.401 2.5878 10.2447 2.74408C10.0884 2.90036 10.0007 3.11232 10.0007 3.33333Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5 9.99996L6.66667 7.49996L10 6.66663L12.5 9.16663L15 9.99996"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      Launch Virtual Tour
                    </nuxt-link>
                  </div>
                </div>
                <!-- FLOOR PLANS -->

                <div v-show="activeTab === 'floor_plan'" id="floor_plan" role="tabpanel">
                  <div
                    class="tw-rounded tw-border tw-p-6 lg:tw-p-10"
                    style="background: var(--c-section_secondary); border-radius: var(--rounded); border-color: var(--c-border)"
                  >
                    <h3>Floor Plans</h3>
                    <floor-plan :data="property.floor_plans" :component="component" class="theme4-floor-plan" />
                  </div>
                </div>
                <div v-show="activeTab === 'map'" id="map" role="tabpanel">
                  <div
                    class="tw-rounded tw-border tw-p-6 lg:tw-p-10"
                    style="background: var(--c-section_secondary); border-radius: var(--rounded); border-color: var(--c-border)"
                  >
                    <h3 class="tw-pb-8">Map</h3>
                    <neuron-google-map
                      v-if="property.map_position && property.map_position.lat && property.map_position.lng"
                      v-model:map-center-position="property.map_position"
                      :component="component"
                      :marker-color="markerColor"
                    />
                  </div>
                </div>
                <div v-show="activeTab === 'epc'" id="epc" role="tabpanel">
                  <div
                    class="tw-rounded tw-border tw-p-6 lg:tw-p-10"
                    style="background: var(--c-section_secondary); border-radius: var(--rounded); border-color: var(--c-border)"
                  >
                    <h3 class="tw-pb-8">EPC</h3>

                    <epc :data="property.epcs" :component="component" />
                  </div>
                </div>
              </div>
            </div>

            <!-- <library-tools-stamp-duty-calculator
            v-if="isMortgageCalculatorVisible"
            :default-price="property.price.numberPrice"
          />
          <library-tools-mortgage-calculator
            v-if="isStampDutyCalculatorVisible"
            :default-price="property.price.numberPrice"
          /> -->
          </div>
          <!-- Property Video, Send Lead: START -->
          <div class="tw-col-span-12 lg:tw-col-span-5 xl:tw-col-span-4">
            <!-- <property-detail-actions
            v-if="!isMobileDevice"
            :property="property"
            :component="component"
            @on-send-lead="onSendLead()"
          /> -->
            <div v-if="sidebarComponents && sidebarComponents.length" class="tw-mt-4">
              <available-components-for-sidebar
                key="property-detail-sidebar"
                key-prefix="property-detail-sidebar"
                :specific-components="sidebarComponents"
              />
            </div>
          </div>
          <!-- Property Video, Send Lead: END -->
        </div>
      </div>

      <full-screen-gallery
        v-if="fullScreenPopupConfig.is_active && activeMedia && property && property.medias"
        v-model:active-media-index="activeMediaIndex"
        :component="component"
        :active-media="activeMedia"
        :count-of-medias="property.medias.length"
        @close="closeLightbox()"
      />

      <popup :config="sendLeadPopupConfig" custom-width="800px">
        <template #content>
          <send-lead v-if="sendLeadPopupConfig.isActive" :property="property" @on-success="onSuccess" @close="sendLeadPopupConfig.isActive = false" />
        </template>
      </popup>
      <popup :config="successModalConfig" @close="onSuccessModalClosed()">
        <template #content>
          <success :text="successPopupText" />
        </template>
      </popup>
    </section>

    <section
      v-else-if="variant === variants.v2"
      class="n-section-secondary !tw-mt-12"
      :style="`background-color: var(--c-${vars.detailBackgroundColor})`"
    >
      <div class="tw-grid tw-w-full tw-grid-cols-12 tw-gap-10">
        <div class="tw-col-span-12 tw-flex tw-flex-col tw-gap-8 lg:tw-col-span-7 xl:tw-col-span-8">
          <div v-if="property?.featured_video?.mediaLink" class="property-detail-card tw-mb-6 tw-block lg:tw-hidden">
            <div class="tw-relative">
              <div class="tw-aspect-[9/16] tw-h-full tw-w-full">
                <iframe
                  class="tw-absolute tw-inset-0 tw-h-full tw-w-full"
                  :src="property.featured_video.mediaLink"
                  style="border-radius: var(--rounded)"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          <div v-if="property.floor_plans" class="property-detail-card">
            <h3>Floor Plans</h3>
            <floor-plan :data="property.floor_plans" :component="component" class="theme4-floor-plan" />
          </div>
          <!-- Property Info -->
          <div v-if="property.description" class="property-detail-card accordion">
            <library-content-accordion
              :collapsed="true"
              :elements="[
                {
                  title: 'Property Info',
                  description: property.description,
                },
              ]"
              :active-index="-1"
            />
          </div>

          <!-- Property Features -->
          <div v-if="property.features" class="property-detail-card accordion">
            <library-content-accordion
              :collapsed="true"
              :elements="[
                {
                  title: 'Features',
                },
              ]"
            >
              <template #description>
                <div class="tw-grid tw-grid-cols-12 tw-gap-4">
                  <div
                    v-for="(feature, index) in property.features"
                    :key="`property-feature-${index}`"
                    class="tw-col-span-12 tw-flex tw-h-[40px] tw-items-center tw-gap-x-3 md:tw-col-span-6"
                  >
                    <span>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                          fill="var(--c-secondary)"
                        />
                      </svg>
                    </span>
                    <library-text class="tw-text-sm tw-font-semibold tw-uppercase" :text="feature"> </library-text>
                  </div>
                </div>
              </template>
            </library-content-accordion>
          </div>

          <!-- Map -->
          <div v-if="property.map_position && property.map_position.lat && property.map_position.lng" class="property-detail-card accordion">
            <library-content-accordion :collapsed="true" :elements="[{ title: 'Map' }]">
              <template #description>
                <neuron-google-map v-model:map-center-position="property.map_position" :component="component" :marker-color="markerColor" />
              </template>
            </library-content-accordion>
          </div>

          <div v-if="property.criteria_list" class="property-detail-card accordion">
            <library-content-accordion
              :collapsed="true"
              :elements="[
                {
                  title: 'Details',
                },
              ]"
            >
              <template #description>
                <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-pt-4">
                  <div
                    v-for="(criteria, index) in property?.criteria_list"
                    :key="`criteria-${index}`"
                    class="tw-col-span-12 md:tw-col-span-6 2xl:tw-col-span-4"
                  >
                    <div style="">
                      <div
                        class="tw-flex tw-items-center tw-gap-4 tw-px-4 tw-py-3"
                        style="background-color: var(--c-section_primary); border-radius: var(--rounded)"
                      >
                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_726_237)">
                            <path
                              d="M50.5655 25.9352L28.0655 3.43521C27.7845 3.15584 27.4043 2.99902 27.008 2.99902C26.6117 2.99902 26.2316 3.15584 25.9505 3.43521L3.45053 25.9352C3.20479 26.2222 3.07638 26.5913 3.09096 26.9688C3.10554 27.3463 3.26204 27.7044 3.52918 27.9716C3.79633 28.2387 4.15444 28.3952 4.53195 28.4098C4.90946 28.4244 5.27858 28.296 5.56553 28.0502L27.0005 6.61521L48.4355 28.0652C48.7225 28.311 49.0916 28.4394 49.4691 28.4248C49.8466 28.4102 50.2047 28.2537 50.4719 27.9866C50.739 27.7194 50.8955 27.3613 50.9101 26.9838C50.9247 26.6063 50.7963 26.2372 50.5505 25.9502L50.5655 25.9352Z"
                              fill="var(--c-secondary)"
                            />
                            <path
                              d="M42 48.0001H34.5V33.0001H19.5V48.0001H12V27.0001L9 30.0001V48.0001C9 48.7958 9.31607 49.5588 9.87868 50.1215C10.4413 50.6841 11.2044 51.0001 12 51.0001H22.5V36.0001H31.5V51.0001H42C42.7957 51.0001 43.5587 50.6841 44.1213 50.1215C44.6839 49.5588 45 48.7958 45 48.0001V29.6401L42 26.6401V48.0001Z"
                              fill="var(--c-secondary)"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_726_237">
                              <rect width="54" height="54" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        <div class="tw-flex tw-flex-col">
                          <p>{{ criteria.title }}</p>
                          <h6>{{ criteria.value }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </library-content-accordion>
          </div>

          <div v-if="property.epcs.length > 0" class="property-detail-card accordion">
            <library-content-accordion :collapsed="true" :elements="[{ title: 'EPC' }]">
              <template #description>
                <epc :data="property.epcs" :component="component" />
              </template>
            </library-content-accordion>
          </div>

          <div v-if="isStampDutyCalculatorVisible" class="property-detail-card accordion">
            <library-content-accordion :collapsed="true" :elements="[{ title: 'Stamp Duty Calculator' }]">
              <template #description>
                <library-tools-stamp-duty-calculator class="accordion-stamp-duty-calculator" :default-price="property.price.numberPrice" />
              </template>
            </library-content-accordion>
          </div>

          <div v-if="isMortgageCalculatorVisible" class="property-detail-card accordion">
            <library-content-accordion :collapsed="true" :elements="[{ title: 'Mortgage Calculator' }]">
              <template #description>
                <library-tools-mortgage-calculator class="accordion-mortgage-calculator" :default-price="property.price.numberPrice" />
              </template>
            </library-content-accordion>
          </div>
        </div>
        <!-- sidebar -->
        <div class="tw-col-span-12 lg:tw-col-span-5 xl:tw-col-span-4">
          <div v-if="sidebarComponents && sidebarComponents.length">
            <div v-if="property.featured_video?.mediaLink" class="property-detail-card tw-mb-6 tw-hidden lg:tw-block">
              <div class="tw-relative">
                <div class="tw-aspect-[9/16] tw-h-full tw-w-full">
                  <iframe
                    class="tw-absolute tw-inset-0 tw-h-full tw-w-full"
                    :src="property.featured_video?.mediaLink"
                    style="border-radius: var(--rounded)"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
            <available-components-for-sidebar
              key="property-detail-sidebar"
              key-prefix="property-detail-sidebar"
              :specific-components="sidebarComponents"
            />
          </div>
        </div>
      </div>

      <full-screen-gallery
        v-if="fullScreenPopupConfig.is_active && activeMedia && property && property.medias"
        v-model:active-media-index="activeMediaIndex"
        :component="component"
        :active-media="activeMedia"
        :count-of-medias="property.medias.length"
        :portrait-video="true"
        @close="closeLightbox()"
      />

      <popup :config="sendLeadPopupConfig" custom-width="800px">
        <template #content>
          <send-lead v-if="sendLeadPopupConfig.isActive" :property="property" @on-success="onSuccess" @close="sendLeadPopupConfig.isActive = false" />
        </template>
      </popup>
      <popup :config="successModalConfig" @close="onSuccessModalClosed()">
        <template #content>
          <success :text="successPopupText" />
        </template>
      </popup>
    </section>
  </div>
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import { ROUTES } from '~/constants/ui.constants';
import { usePropertiesStore } from '~/units/properties/stores';
import type { Component } from '~/types';
import { ComponentMixin } from '~/mixins/component.mixin';
import { PropertyMixin } from '~/units/properties/mixins/property.mixin';
import FloorPlan from '~/components/common/FloorPlan.vue';
import NeuronGoogleMap from '~/components/common/NeuronGoogleMap.vue';
import Epc from '~/components/common/Epc.vue';
import NcSelect from '~/components/common/inputs/NcSelect.vue';
import NcInput from '~/components/common/inputs/NcInput.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import FullScreenGallery from '~/components/common/FullScreenGallery.vue';
import { useCoreStore } from '~/units/core/store';
import SendLead from '~/components/common/leads/SendLead.vue';
import Success from '~/components/common/popup/Success.vue';
import Popup from '~/components/common/popup/Popup.vue';
import PropertyDetailActions from '~/components/theme4/properties/PropertyDetailActions.vue';
import AvailableComponentsForSidebar from '~/components/theme4/AvailableComponentsForSidebar.vue';
import { FullScreenPopupMixin } from '~/units/core/mixins/fullScreenPopupMixin';
import { getImagesByCount } from '~/units/properties/store';

export default defineNuxtComponent({
  name: 'Theme4PropertyDetail',
  components: {
    AvailableComponentsForSidebar,
    PropertyDetailActions,
    Popup,
    Success,
    SendLead,
    FullScreenGallery,
    NcButton,
    NcInput,
    NcSelect,
    Epc,
    NeuronGoogleMap,
    FloorPlan,
  },

  mixins: [ComponentMixin, PropertyMixin, FullScreenPopupMixin],

  data() {
    return {
      activeTab: 'features',
      swiper: {},
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    tabs() {
      const tabs = [
        {
          name: 'features',
          title: 'Features',
          is_visible: true,
        },
        {
          name: 'information',
          title: 'Property Info',
          is_visible: true,
        },
        {
          name: 'video',
          title: 'Property Video',
          is_visible: !!this.property?.featured_video,
        },
        {
          name: 'virtual-tour',
          title: 'Virtual Tour',
          is_visible: !!this.property?.virtual_tour,
        },
        {
          name: 'floor_plan',
          title: 'Floor Plan',
          is_visible: !!this.property?.floor_plans?.length,
        },
        {
          name: 'map',
          title: 'Map',
          is_visible: true,
        },
        {
          name: 'epc',
          title: 'EPC',
          is_visible: !!this.property?.epcs?.length,
        },
      ];

      return tabs.filter((i) => i.is_visible);
    },

    markerColor() {
      return this.colors.primary;
    },

    ROUTES() {
      return ROUTES;
    },

    primaryFivePhotos() {
      if (this.property) {
        return getImagesByCount(this.property, 5);
      }
      return [];
    },

    primarySevenPhotos() {
      if (this.property) {
        return getImagesByCount(this.property, 7);
      }
      return [];
    },

    sidebarComponents(): Component[] {
      if (this.currentPage && this.currentPage.sidebar) {
        return this.currentPage.sidebar;
      }
      return [];
    },

    isMortgageCalculatorVisible() {
      return !this.vars.isMortgageCalculatorHiddenNumber;
    },

    isStampDutyCalculatorVisible() {
      return !this.vars.isStampDutyCalculatorHiddenNumber;
    },

    isLetterPropertyCriteria() {
      return this.vars.isLetterPropertyCriteriaNumber;
    },
  },

  methods: {
    ...mapActions(useCoreStore, ['shareApi', 'openTab']),

    async onShared() {
      await this.shareApi({
        title: this.property?.advert_heading,
        text: this.property?.advert_heading,
        url: window.location.href,
      });
    },

    onSwiper(swiper: object) {
      this.swiper = swiper;
    },
  },

  async setup(props: { component: Component }) {
    const store = usePropertiesStore();
    await store.fetchSingleProperty(props.component);
  },
});
</script>

<style scoped>
.share-button {
  border: 2px solid var(--camel);
}

.nav-link.active {
  color: var(--purple);
  border-bottom-color: var(--purple);
}

.nav-link {
  color: var(--black);
  font-size: 16px;
}

.nc-first-image-overlay-item {
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  transform: translate(-50%, -50%);
  text-align: right;
  background-color: var(--white);
  color: var(--nc-classic-black);
  border-radius: 4px;
}

.nc-all-photos-button {
  position: absolute;
  bottom: 20px;
  left: 70px;
  padding: 10px;
  border-radius: 4px;
  background-color: var(--white);
  color: var(--nc-classic-black);
}

.photo-next,
.photo-prev {
  position: absolute;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 44px;
  height: 64px;
  background-color: #fff;
  border-radius: var(--rounded);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  font-size: 26px;
}

.photo-next {
  right: 5%;
}

.photo-prev {
  left: 5%;
}

::v-deep(.floor-tab .floor-plan-items .floor-plan-card) {
  width: 100% !important;
}

::v-deep(.floor-tab .floor-plan-items .floor-plan-card figure) {
  border: none !important;
}

::v-deep(.floor-tab) {
  padding: unset !important;
}

::v-deep(.inline-iframe-container iframe) {
  height: 590px;
}

.theme4-floor-plan ::v-deep(figure) {
  height: unset !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: unset;
}
.swiper-button-next,
.swiper-button-prev {
  width: unset;
  height: unset;
}

::v-deep(img) {
  border-radius: 4px;
}

.property-detail-card {
  padding: 24px;
  border-radius: var(--rounded);
  background-color: var(--c-section_primary);
}

.property-detail-card.accordion {
  padding-bottom: 0;
  padding-top: 0;
}

@media screen and (min-width: 1024px) {
  .property-detail-card {
    padding: 32px;
  }

  .property-detail-card.accordion {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.accordion-stamp-duty-calculator ::v-deep(h3),
.accordion-mortgage-calculator ::v-deep(h3) {
  display: none;
}

.accordion-stamp-duty-calculator ::v-deep(.stamp-duty-result),
.accordion-mortgage-calculator ::v-deep(.mortgage-result) {
  background: var(--c-primary);
  border-radius: var(--rounded);
}
.accordion-stamp-duty-calculator ::v-deep(select),
.accordion-stamp-duty-calculator ::v-deep(.n-input),
.accordion-stamp-duty-calculator ::v-deep(input),
.accordion-mortgage-calculator ::v-deep(select),
.accordion-mortgage-calculator ::v-deep(.n-input),
.accordion-mortgage-calculator ::v-deep(input) {
  background: var(--c-input_secondary) !important;
}
</style>
