<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleTop'" :duration="700">
      <div v-if="variants.v1 === variant" class="tw-w-full tw-text-center">
        <h3 class="tw-mb-8">{{ vars.titleText }}</h3>

        <!-- Mobile Slider -->

        <Swiper
          :modules="[SwiperPagination]"
          :pagination="true"
          :slides-per-view="1"
          :space-between="30"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
          }"
          class="tw-w-full lg:!tw-hidden"
        >
          <SwiperSlide v-for="(item, index) in cards" :key="`location-${index}`" class="!tw-flex !tw-h-auto tw-items-center tw-justify-center">
            <div class="tw-relative">
              <NuxtImg :src="item.photo" class="tw-inset-0 tw-h-full tw-min-h-[320px] tw-w-full" />
              <div
                class="tw-absolute tw-inset-0 tw-flex tw-flex-col-reverse tw-items-center tw-rounded-lg tw-bg-black tw-bg-opacity-20 tw-text-white"
              >
                <h4 class="tw-pb-4">{{ item.title }}</h4>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <!-- Dekstop Grid View -->

        <div class="tw-hidden tw-w-full tw-grid-cols-3 tw-grid-rows-2 tw-gap-6 lg:tw-grid xl:tw-gap-10">
          <nuxt-link
            v-for="(item, index) in cards"
            :to="item.link"
            :external="true"
            :key="`location-${index}`"
            class="n-link tw-group tw-relative tw-max-h-96 tw-overflow-hidden tw-rounded-lg tw-duration-300 hover:tw-scale-95"
            :class="{ 'tw-col-span-2': item.wide }"
          >
            <NuxtImg
              :src="item.photo"
              class="tw-inset-0 tw-z-0 tw-h-full tw-w-full tw-duration-300 group-hover:tw-rotate-2 group-hover:tw-scale-105"
            />
            <div class="tw-absolute tw-inset-0 tw-flex tw-flex-col-reverse tw-items-center tw-rounded-lg tw-bg-black tw-bg-opacity-20 tw-text-white">
              <h4 class="tw-pb-4">{{ item.title }}</h4>
            </div>
          </nuxt-link>
        </div>
      </div>

      <div v-if="variants.v2 === variant" class="tw-relative tw-w-full tw-text-center">
        <h2 class="tw-mb-8">{{ vars.titleText }}</h2>

        <!-- Mobile Slider -->
        <div class="tw-relative tw-mb-10 lg:tw-mb-0">
          <Swiper
            :modules="[SwiperPagination]"
            :slides-per-view="1"
            :space-between="30"
            effect="fade"
            :clickable="true"
            @slideChange="onSlideChange"
            @swiper="onSwiper"
            :breakpoints="{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }"
            class="tw-w-full lg:!tw-hidden"
          >
            <SwiperSlide
              v-for="(item, index) in cards"
              :key="`location-${index}`"
              class="!tw-flex !tw-h-auto tw-max-w-none tw-items-center tw-justify-center"
            >
              <div class="tw-relative">
                <NuxtImg :src="item.photo" class="tw-inset-0 tw-h-full tw-min-h-[320px] tw-w-full" />
                <div
                  class="tw-absolute tw-inset-0 tw-flex tw-items-end tw-justify-start tw-rounded-lg tw-bg-black tw-bg-opacity-20 tw-p-5 tw-text-white"
                >
                  <div class="tw-flex tw-items-center tw-gap-3">
                    <div class="tw-rounded-md tw-bg-white tw-px-3 tw-py-1 tw-font-bold tw-tracking-wider tw-text-black">
                      {{ item.type }}
                    </div>
                    <p class="">{{ item.propertiesCount }} properties</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div class="lg:!tw-hidden">
            <LibrarySliderPagination :swiper="swiper" variant="v3" :can-paginate-back="canPaginateBack" :can-paginate-next="canPaginateNext" />
          </div>
        </div>
        <!-- Dekstop Grid View -->

        <div class="tw-mb-10 tw-hidden tw-w-full tw-grid-cols-3 tw-grid-rows-2 tw-gap-6 lg:tw-grid xl:tw-gap-10">
          <nuxt-link
            v-for="(item, index) in cards"
            :to="generateLink(item)"
            :external="true"
            :key="`location-${index}`"
            class="n-link tw-group tw-relative tw-max-h-96 tw-overflow-hidden tw-rounded-lg tw-duration-300 hover:tw-scale-95"
            :class="{ 'tw-col-span-2': item.wide }"
          >
            <NuxtImg
              :src="item.photo"
              class="tw-inset-0 tw-z-0 tw-h-full tw-w-full tw-duration-300 group-hover:tw-rotate-2 group-hover:tw-scale-105"
            />
            <div class="tw-absolute tw-inset-0 tw-flex tw-items-end tw-justify-start tw-rounded-lg tw-bg-black tw-bg-opacity-20 tw-p-5 tw-text-white">
              <div class="tw-flex tw-items-center tw-gap-3">
                <div class="tw-rounded-md tw-bg-white tw-px-3 tw-py-1 tw-font-bold tw-tracking-wider tw-text-black">
                  {{ item.type }}
                </div>
                <p>{{ parseFormV2(item.item)?.property_count || 0 }} properties</p>
              </div>
            </div>
          </nuxt-link>
        </div>
        <nuxt-link :to="vars.buttonLinkUrl" :class="`n-${vars.buttonTypeText}`">
          {{ vars.buttonLabelText }}
        </nuxt-link>
      </div>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurLocations',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      property: null,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },

    generateLink(item) {
      const locationData = this.parseFormV2(item.item)?.locations || {};

      const encodedLocation = encodeURIComponent(JSON.stringify(locationData));

      const url = `/sales/property-for-sale?locations=${encodedLocation}&is_available=true&sort=best_match`;

      return url;
    },
  },
  mounted() {
    this.onSlideChange();
  },
});
</script>
